import {
  FaExclamationTriangle,
  FaPaw,
  FaStar,
  FaThumbsUp,
} from "react-icons/fa";
import {
  SiInstagram,
  SiTwitter,
  SiTiktok,
  SiFacebook,
  SiYoutube,
  SiTelegram,
  SiLinkedin,
  SiSpotify,
  SiWhatsapp,
  SiVk,
  SiTwitch,
} from "react-icons/si";
export const iconMap = {
  "New Services": { icon: FaStar, color: "yellow.400" },
  Recommended: { icon: FaThumbsUp, color: "green.400" },
  "Telegram PREMIUM Members": { icon: SiTelegram, color: "#0088cc" },
  "Telegram PREMIUM Members (Targeted)": {
    icon: SiTelegram,
    color: "#0088cc",
  },
  "Telegram PREMIUM Channel Boost": { icon: SiTelegram, color: "#0088cc" },
  "Telegram PREMIUM Views": { icon: SiTelegram, color: "#0088cc" },
  "Telegram PREMIUM BOT Start": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Members": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Targeted Members": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Post Views": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Auto Views - NEW and OLD Posts": {
    icon: SiTelegram,
    color: "#0088cc",
  },
  "Telegram Targeted Views With Statistics / AUTO Views": {
    icon: SiTelegram,
    color: "#0088cc",
  },
  "Telegram Targeted Views From Search / AUTO Views": {
    icon: SiTelegram,
    color: "#0088cc",
  },
  "Telegram Post Promotion (Statistics Views + Reaction + Comment + Share)": {
    icon: SiTelegram,
    color: "#0088cc",
  },
  "Telegram Reactions": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Premium Reactions": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Auto Reactions": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Story Views": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Comments": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Post Share + Targeted": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Telesco.pe Views": { icon: SiTelegram, color: "#0088cc" },
  "Telegram BOT Start": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Auto Comments / Shares": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Auto Views Per Minute": { icon: SiTelegram, color: "#0088cc" },
  "Instagram Followers [No Refill]": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Followers [Guaranteed]": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Followers [Targeted]": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Followers BIG Packages": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Likes [No Refill]": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Likes [Refill]": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Likes [Targeted]": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Views": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Save / Story / Impressions / Reach / Profile Visit": {
    icon: SiInstagram,
    color: "#E1306C",
  },
  "Instagram Comments": { icon: SiInstagram, color: "#E1306C" },
  IGTV: { icon: SiInstagram, color: "#E1306C" },
  "Instagram Reel": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Mentions": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Live Video": { icon: SiInstagram, color: "#E1306C" },
  "Instagram VERIFIED Services": { icon: SiInstagram, color: "#E1306C" },
  "Instagram VERIFIED Services Turkey": {
    icon: SiInstagram,
    color: "#E1306C",
  },
  "Instagram VERIFIED Services EGYPT": {
    icon: SiInstagram,
    color: "#E1306C",
  },
  "Instagram Auto Likes": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Auto Likes Targeted": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Auto Views": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Auto Comments / Save / Story / Impressions": {
    icon: SiInstagram,
    color: "#E1306C",
  },
  Threads: { icon: SiInstagram, color: "#E1306C" }, // Assuming Threads icon is same as Instagram
  "Youtube Watchtime [WORK AFTER UPDATE]": {
    icon: SiYoutube,
    color: "#FF0000",
  },
  "Youtube Subscribers [No refill]": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Subscribers [Guaranteed]": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Shorts": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Views [No Refill]": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Views [Guaranteed]": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Views [Targeted]": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Views [Mix / Targeted From ADS]": {
    icon: SiYoutube,
    color: "#FF0000",
  },
  "Youtube Views [RAV™ - GS Unique][Language - Targeted]": {
    icon: SiYoutube,
    color: "#FF0000",
  },
  "Youtube Views [RAV™- Unique][GEO - Targeted]": {
    icon: SiYoutube,
    color: "#FF0000",
  },
  "Youtube Likes / DisLikes [No Refill]": {
    icon: SiYoutube,
    color: "#FF0000",
  },
  "Youtube Likes / DisLikes  [Guaranteed]": {
    icon: SiYoutube,
    color: "#FF0000",
  },
  "Youtube Shorts Views / Likes": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Comments Mix / Target": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Live Stream / Premiere": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Social Shares": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Shares": { icon: SiYoutube, color: "#FF0000" },
  "Facebook Followers / Friends / Group members": {
    icon: SiFacebook,
    color: "#1877F2",
  },
  "Facebook Followers / Friends / Group members Targeted": {
    icon: SiFacebook,
    color: "#1877F2",
  },
  "Facebook Page Likes": { icon: SiFacebook, color: "#1877F2" },
  "Facebook Live Stream Views": { icon: SiFacebook, color: "#1877F2" },
  "Facebook Video Views": { icon: SiFacebook, color: "#1877F2" },
  "Facebook Post Likes / Stories / Shares / Events": {
    icon: SiFacebook,
    color: "#1877F2",
  },
  "Facebook Post Likes Targeted": { icon: SiFacebook, color: "#1877F2" },
  "Facebook Post Comments": { icon: SiFacebook, color: "#1877F2" },
  "TikTok Followers": { icon: SiTiktok, color: "#69C9D0" },
  "TikTok Views": { icon: SiTiktok, color: "#69C9D0" },
  "TikTok Likes / Shares / Comments": { icon: SiTiktok, color: "#69C9D0" },
  "TikTok Livestream Views": { icon: SiTiktok, color: "#69C9D0" },
  "VK.com": { icon: SiVk, color: "#4C75A3" },
  Twitch: { icon: SiTwitch, color: "#9146FF" },
  "Twitter NFT Services": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Followers": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Retweets": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Likes": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Clicks": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Impressions": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Poll Votes": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Views / Comments": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Live / Space Listeners": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Data Scraper": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter USA Follower Services": { icon: SiTwitter, color: "#1DA1F2" },
  LinkedIn: { icon: SiLinkedin, color: "#0077B5" },
  "Spotify Free Plays": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Premium Plays": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Followers": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Podcast Plays": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Saves": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Monthly Listeners": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Search Plays": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Playlist Plays": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Playlist Followers": { icon: SiSpotify, color: "#1DB954" },
  "WhatsApp 📌NEW": { icon: SiWhatsapp, color: "#25D366" },
  "⏺ TEST [Do not order]": { icon: FaExclamationTriangle, color: "red.500" },
  "Hamster Kombat": { icon: FaPaw, color: "brown.500" },
};

export const excludeIds = [
  "2980",
  "3031",
  "2060",
  "2446",
  "3148",
  "2977",
  "2068",
  "1983",
  "2976",
  "38",
  "2978",
  "2944",
  "3140",
  "2939",
  "3142",
  "2059",
  "2104",
  "3032",
  "771",
  "3030",
  "3149",
  "3150",
  "1959",
  "2094",
  "2096",
  "2526",
  "2527",
  "2518",
  "441",
  "2499",
  "442",
  "2489",
  "2490",
  "323",
  "2848",
  "3073",
  "2654",
  "2845",
  "796",
  "2537",
  "3173",
  "3108",
  "2826",
  "2649",
  "3080",
  "2143",
  "2812",
  "2811",
  "3172",
  "2355",
  "2351",
  "2352",
  "2353",
  "2356",
  "2357",
  "2359",
  "2360",
  "2361",
  "2362",
  "2363",
  "2365",
  "2366",
  "2376",
  "2364",
  "2367",
  "2369",
  "2370",
  "2371",
  "2372",
  "2373",
  "2374",
  "2375",
  "2381",
  "2383",
  "2382",
  "2377",
  "2378",
  "2379",
  "2380",
  "2072",
  "2073",
  "2074",
  "2075",
  "2403",
  "2404",
  "2405",
  "2406",
  "2407",
  "2408",
  "2409",
  "2410",
  "2411",
  "2412",
  "2413",
  "2414",
  "2415",
  "2416",
  "2417",
  "2418",
  "2419",
  "2420",
  "2421",
  "2422",
  "2423",
  "2424",
  "2425",
  "2426",
  "2430",
  "2431",
  "2432",
  "2433",
  "2434",
  "2979",
  "2941",
  "2940",
  "3141",
  "3147",
  "2839",
  "2943",
 "2942",
"2157",
"2982",
"2097",
"2897",
"836",
"2907",
"865",
"2037",
"2544"
];

export const excludedServiceCategories = [
  "Instagram followers [no refill]",
  "Instagram followers [targeted]",
  "Instagram followers Big Packages",
  "Instagram likes [no refill]",
  "Instagram verified services",
  "Instagram verified services turkey",
  "Instagram verified services Egypt",
  "Instagram auto likes",
  "Instagram auto likes targeted",
  "Instagram auto views",
  "Instagram auto comments / save / story / impression /",
  "Youtube subscribers [no refill]",
  "Youtube views [no refill]",
  "Youtube views [no refill]",
  "Youtube likes / dislikes [no refill]",
  "Hamster kombat",
  "⏺ TEST [Do not order]",
  "Twitter likes",
  "Facebook Page likes",
  "instagram followers guaranteed"
];
